import React, { useEffect, useState } from "react";
import ButtonSocial from "../button/ButtonSocial";
import FacebookIcon from "../../assets/images/Facebook-Icon.svg";


import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import sweetAlert from "../../services/sweetAlert";
import AppConfig from "../../utils/config";
const CONFIG = AppConfig.config()
const firebaseConfig = {
  apiKey: CONFIG.SOCIAL.API_KEY,
  authDomain: CONFIG.SOCIAL.AUTH_DOMAIN,
  projectId: CONFIG.SOCIAL.PROJECT_ID,
  storageBucket: CONFIG.SOCIAL.STORAGE_BUCKET,
  messagingSenderId: CONFIG.SOCIAL.MESSAGING_SENDER_ID,
  appId: CONFIG.SOCIAL.APP_ID,
  measurementId: CONFIG.SOCIAL.MEASUREMENT_ID,
};

class LoginWithFacebook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      uid: "",
      email: "",
      source_id: "",
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    this.handleLoginFacebook = this.handleLoginFacebook.bind(this);
  }

  componentDidMount() {
    let auth = firebase.auth();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        console.log("auth", user);
        this.setState({
          userName: user.displayName,
          uid: user.uid,
          email: user.email,
        });
      } else {
        // No user is signed in.
      }
    });
  }

  handleLoginFacebook() {
    var provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('public_profile');
    provider.addScope('email');
    // firebase.auth().signInWithRedirect(provider); signInWithPopup
    firebase
      .auth()
      // .signInWithRedirect(provider)
      .signInWithPopup(provider)
      .then((result) => {
        console.log("result handleLoginFacebook >> ", result);
        let aaa = firebase.auth().currentUser
        console.log('aaa >>', aaa)
        const token = result.credential.accessToken;
        const user = result.user;
        const email = user.email ? user.email : result.additionalUserInfo.profile.email;
        const uid = result.user.uid;
        const profile = {
          facebookUid: user.uid,
          email: email,
          displayName: user.displayName,
          phoneNumber: user.phoneNumber,
          photoURL: user.photoURL
        }
        firebase.auth().signOut().then(()=> {
          console.log('FACEBOOK LOGOUT SUCCESS!')
        })
        this.props.onLoginFacebook(uid, profile, result)
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log('BBB', error.code === 'auth/account-exists-with-different-credential')
        console.log(errorCode)
        console.log(errorMessage)
        // if (error.code)
        if (error.code === 'auth/account-exists-with-different-credential') {
          sweetAlert.AlertInfo({
            errorCode: "errorCode",
            titleClass: 'title-danger',
            title: "You can’t use this email",
            subtitle: `This email address is already associated with Google. <b>Please Sign in by Google.</b>`,
          });
        }
      });
  }

  render() {
    return (
      <>
        <ButtonSocial
          imgLink={FacebookIcon}
          label={"Sign in with Facebook"}
          onClick={() => {
            console.log("LOGIN Facebook");
            this.handleLoginFacebook();
          }}
        />

        {
          false ? (
            <>
              <div>
                {this.state.email ? this.state.email : " - "} /{" "}
                {this.state.userName ? this.state.userName : " - "} /{" "}
                {this.state.uid ? this.state.uid : " - "}
              </div>
              <div>{this.state.source_id ? this.state.source_id : " - "}</div>
              <div>
                {" "}
                <button onClick={this.handleOmiseCard}> handleOmise </button>{" "}
              </div>
            </>
          )
          : <></>
          }
      </>
    );
  }
}

export default LoginWithFacebook;
